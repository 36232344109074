/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useRef } from 'react'
import { useProjects, useTasks } from '../hooks/useProjects'
import Button from '../components/common/Button'
import { CalendarIcon } from '../img/CalendarIcon'
import { toggleCalendar, useOutsideClickHandler } from '../utils/calendarUtils'
import CalendarCard from '../components/common/CalendarCard'

import ListComponent from '../components/lists/ListComponent'
import { Navigate, useNavigate } from 'react-router-dom'

export default function HomePage () {
  const [sortOrder, setSortOrder] = useState('asc')
  const [sortField, setSortField] = useState('name')
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [sortOrder2, setSortOrder2] = useState('asc')
  const [sortField2, setSortField2] = useState('name')
  const [startDate, setStartDate] = useState(new Date(0))
  const [endDate, setEndDate] = useState(new Date())
  const [searchTerm, setSearchTerm] = useState('')
  const calendarRef = useRef(null)
  const calendarButtonRef = useRef(null)

  const navigate = useNavigate()

  const projects = useProjects(sortField, sortOrder)

  const tasks = useTasks(sortField2, sortOrder2)

  // // Then at render time, you apply the filters:
  // const filteredProjects = useMemo(() => {
  //   return projects
  //     .filter(project => {
  //       if (project.timestamp?.toDate) {
  //         const projectDate = project.timestamp.toDate()
  //         return projectDate >= startDate && projectDate <= endDate
  //       }
  //       return true
  //     })
  //     .filter(project =>
  //       project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //       project.scope.toLowerCase().includes(searchTerm.toLowerCase())
  //     )
  // }, [projects, startDate, endDate, searchTerm])

  const filteredProjects = useMemo(() => {
    return projects
      .filter((item) => {
      // Filter by date range if timestamp exists
        if (item.timestamp?.toDate) {
          const itemDate = item.timestamp.toDate()
          const itemDateNoTime = new Date(itemDate)
          const startDateNoTime = new Date(startDate)
          const endDateNoTime = new Date(endDate)
          startDateNoTime.setHours(0, 0, 0, 0)
          endDateNoTime.setHours(23, 59, 59, 999)
          itemDateNoTime.setHours(0, 0, 0, 0) // zero out the time

          return itemDateNoTime >= startDateNoTime && itemDateNoTime <= endDateNoTime
        }
        return true
      })
  }, [tasks, startDate, endDate, searchTerm])

  // const filteredProjects2 = useMemo(() => {
  //   return tasks
  //     .filter(project => {
  //       if (project.timestamp?.toDate) {
  //         const projectDate = project.timestamp.toDate()
  //         return projectDate >= startDate && projectDate <= endDate
  //       }
  //       return true
  //     })
  //     .filter(project =>
  //       project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //       project.scope.toLowerCase().includes(searchTerm.toLowerCase())
  //     )
  // }, [tasks, startDate, endDate, searchTerm])

  // Re-compute filteredData whenever data or filters change
  const filteredTasks = useMemo(() => {
    return tasks
      .filter((item) => {
      // Filter by date range if timestamp exists
        if (item.timestamp?.toDate) {
          const itemDate = item.timestamp.toDate()
          const itemDateNoTime = new Date(itemDate)
          const startDateNoTime = new Date(startDate)
          const endDateNoTime = new Date(endDate)
          startDateNoTime.setHours(0, 0, 0, 0)
          endDateNoTime.setHours(23, 59, 59, 999)
          itemDateNoTime.setHours(0, 0, 0, 0) // zero out the time

          return itemDateNoTime >= startDateNoTime && itemDateNoTime <= endDateNoTime
        }
        return true
      })
  }, [tasks, startDate, endDate, searchTerm])

  useOutsideClickHandler(isCalendarOpen, setIsCalendarOpen, calendarRef, calendarButtonRef)

  return (
    <>
      <div className="block">
        {/* Buttons */}
        <div className="relative">
          <div className="flex items-center gap-2 mb-4">
            {/* "Select dates" Button */}    <Button
              logo={<CalendarIcon className="h-5 w-5" />}
              text="Select dates"
              className="logo-button"
              onClick={(e) => toggleCalendar(e, setIsCalendarOpen)}
              ref={calendarButtonRef}
            />
          </div>
          {/* Calendar modal */}
          {isCalendarOpen && (
            <div className="absolute top-full mt-2 left-0 bg-white border border-gray-300 shadow-lg rounded-lg z-50"
              ref={calendarRef}>
              <CalendarCard startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate}
                isCalendarOpen={isCalendarOpen} setIsCalendarOpen={setIsCalendarOpen} />
            </div>
          )}
        </div>
        <div className="flex space-y-2.5 flex-col xl-custom:flex-row xl-custom:space-x-2.5 xl-custom:space-y-0">
          <ListComponent
            projects={filteredProjects}
            currentPage={0}
            onPageChange={0}
            sortOrder={sortOrder}
            sortField={sortField}
            listType="projects"
            onSortChange={(field, order) => {
              setSortField(field)
              setSortOrder(order)
            }}
            footer={
              <div>
                <hr className="border-t border-gray-200" />
                <div className="flex items-center justify-between pt-5">
                  <button onClick={() => navigate('/list/projects')} className="text-sm font-semibold border border-gray-300 px-3 py-2 rounded-md text-gray-900 hover:text-gray-700">View All App Analyses</button>
                </div>
              </div>
            }
          />
          <ListComponent
            projects={filteredTasks}
            currentPage={0}
            onPageChange={0}
            sortOrder={sortOrder2}
            sortField={sortField2}
            listType="tasks"
            onSortChange={(field2, order2) => {
              setSortField2(field2)
              setSortOrder2(order2)
            }}
            footer={
              <div>
                <div className="flex items-center justify-between pt-5">
                  <button onClick={() => navigate('/list/tasks')} className="text-sm font-semibold border border-gray-300 px-3 py-2 rounded-md text-gray-900 hover:text-gray-700">View All Task Analyses</button>
                </div>
              </div>
            }
          />
        </div>
      </div>
    </>
  )
}
