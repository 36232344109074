import React, { useEffect, useCallback, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { doc, getDoc } from 'firebase/firestore'
import { getStorage, ref, getDownloadURL, getMetadata } from 'firebase/storage'
import { db } from '../firebase'
import ReactToPrint from 'react-to-print'
import Button from '../components/common/Button'
import { IntroComponent } from '../components/pdf/IntroComponent'
import { IntroTwoComponent } from '../components/pdf/IntroTwoComponent'
import { IntroThreeComponent } from '../components/pdf/IntroThreeComponent'
import { IntroFourComponent } from '../components/pdf/IntroFourComponent'
import { EndComponent } from '../components/pdf/EndComponent'
import { GenDataComponent } from '../components/pdf/GenDataComponent'
import { CardsDataComponent } from '../components/pdf/CardsDataComponent'
import IframeComponent from '../components/pdf/IframeComponent'
import '../components/pdf/createReport.css'
import Container from '../components/layout/container/Container'
import { CheckCircleIcon } from '@heroicons/react/24/outline'

const PDFPreviewPage = () => {
  const { id } = useParams()
  const [jsonDataArray, setJsonDataArray] = useState([])
  const [pdfData, setPdfData] = useState(null)
  const [uploadDate, setUploadDate] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const printRef = useRef()
  const fetchDataExecuted = useRef(false)

  const { listType } = useParams()

  const scopeKey = listType === 'projects' ? 'scope' : 'jiraId'

  console.log('pdfData', pdfData)

  const sortAudits = (audits) => {
    const errorArray = []
    const passedArray = []
    const manualArray = []
    const notApplArray = []

    for (const auditKey in audits) {
      const audit = audits[auditKey]
      if (audit.score === 0) {
        errorArray.push(audit)
      } else if (audit.score === 1) {
        passedArray.push(audit)
      } else {
        if (audit.scoreDisplayMode === 'notApplicable') {
          notApplArray.push(audit)
        } else {
          manualArray.push(audit)
        }
      }
    }

    return { errorArray, passedArray, manualArray, notApplArray }
  }

  const fetchData = useCallback(async () => {
    if (fetchDataExecuted.current) return
    fetchDataExecuted.current = true

    try {
      setIsLoading(true)
      const docRef = doc(db, `accessibility-${listType}`, id)
      const docSnap = await getDoc(docRef)

      if (!docSnap.exists()) {
        throw new Error('No such document!')
      }

      const data = docSnap.data().config
      setPdfData(data)

      const projectName = data.name.replace(/\s/g, '_')
      const scopeValue = data[scopeKey].replace(/\s/g, '_')
      document.title = `Okb_Interactive-${projectName}-${scopeValue}-Web_Accessibility_Analysis`

      console.log('Project data:', data)

      const storage = getStorage()
      const tempData = []

      for (const [index, webpage] of data.webpages.entries()) {
        console.log(`Processing webpage ${index + 1}:`, webpage.url)

        if (!webpage.path || !webpage.webPath) {
          console.error(`Invalid path or webPath for webpage ${index + 1}`)
          continue // Saltar esta página si no tiene un path o webPath válido
        }

        try {
          const storageRef = ref(storage, webpage.path)
          const url = await getDownloadURL(storageRef)
          const uniqueUrl = `${url}&t=${Date.now()}`
          const metadata = await getMetadata(storageRef)

          const response = await fetch(uniqueUrl, { cache: 'no-store' })
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
          }

          console.log(`Fetching from URL for webpage ${index + 1}: ${uniqueUrl}`)
          console.log(`Path for webpage ${index + 1}: ${webpage.path}`)
          const jsonData = await response.json()
          console.log(`Raw data fetched for webpage ${index + 1}:`, jsonData)
          console.log(`Accessibility score for webpage ${index + 1}:`, jsonData.categories.accessibility.score)

          const processedData = {
            baseUrl: data.url,
            name: webpage.name,
            requestedUrl: `${data.url}${webpage.webPath}`,
            webPath: webpage.webPath,
            ...jsonData,
            ...sortAudits(jsonData.audits)
          }

          processedData.accessibilityScore = Math.round(jsonData.categories.accessibility.score * 100)

          setUploadDate(metadata.timeCreated)

          console.log(`Processed data for webpage ${index + 1}:`, processedData)
          tempData.push({ ...processedData })
        } catch (error) {
          console.error(`Error processing webpage ${index + 1}:`, error)
        }
      }

      console.log('All processed data:', tempData)

      // Check for duplicate data
      const uniqueScores = new Set(tempData.map(data => data.accessibilityScore))
      if (uniqueScores.size !== tempData.length) {
        console.warn('Warning: Duplicate accessibility scores detected!')
      }

      setJsonDataArray(tempData)
    } catch (error) {
      console.error('Failed to fetch data:', error)
      setError(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [id])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (isLoading) return <div>Loading...</div>
  if (error) return <div>Error: {error}</div>

  console.log('check pdfData', pdfData)

  return (
    <Container>
      <div className="px-5 py-6 sm:px-8">
        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <h1 className="text-lg font-bold">{pdfData.name}</h1>
            <p className="flex mt-1 text-sm text-gray-600 justify-center">
              {new Date(uploadDate).toLocaleDateString('es-ES', {
                day: '2-digit',
                month: 'numeric',
                year: 'numeric'
              })}
              <div className="inline-flex ml-2">
                <span className={`inline-flex whitespace-nowrap text-xs items-center justify-center gap-1 leading-[1.125rem] pr-2 pl-1.5 py-0.5 w-fit max-w-32 max-h-5 rounded-full border-[1px] font-medium ${pdfData.environment === 'Production'
                  ? 'border-light-blue bg-pale-blue text-dark-blue'
                  : pdfData.environment === 'Pre-Production'
                    ? 'border-light-pink bg-pale-pink text-dark-pink'
                    : 'border-gray-300 bg-gray-50 text-gray-600'
                  }`}>
                  <span className={`rounded-full w-[6px] h-[6px] items-center justify-center ${pdfData.environment === 'Production'
                    ? 'bg-mid-blue'
                    : pdfData.environment === 'Pre-Production'
                      ? 'bg-mid-pink'
                      : 'bg-gray-600'
                    }`}></span>
                  {pdfData.environment || 'No environment'}
                </span>
              </div>
            </p>
          </div>
          <div className="ml-4 mt-4 shrink-0">
            <ReactToPrint
              trigger={() => (
                <Button logo={<CheckCircleIcon aria-hidden="true" className="h-5 w-5" />} text="Download" logoPosition='right' className='bg-default-violet text-white' />
              )}
              content={() => printRef.current}
            />
          </div>
        </div>
        <hr className="border-t border-gray-200 my-5" />
      </div>

      <IframeComponent>
        <div className="preview-wrapper">
          <div className="preview-content">
            <div ref={printRef} className="print-container">
              <IntroComponent projectData={pdfData} />
              { listType === 'projects' && (
                <>
                  <IntroTwoComponent projectData={pdfData} />
                  <IntroThreeComponent projectData={pdfData} />
                  <IntroFourComponent projectData={pdfData} />
                </>
              )}
              {jsonDataArray.map((dataJson, index) => (
                <React.Fragment key={`fragment-${index}`}>
                  <GenDataComponent
                    project={pdfData.name}
                    baseURL={pdfData.url}
                    score={dataJson.accessibilityScore}
                    path={dataJson.webPath}
                    errorNum={dataJson.errorArray.length}
                    passedNum={dataJson.passedArray.length}
                    notApplNum={dataJson.notApplArray.length}
                    manualNum={dataJson.manualArray.length}
                    manualArray={dataJson.manualArray}
                    dynamicScope={pdfData[scopeKey]}
                    scopeKey={scopeKey}
                    uploadDate={uploadDate}
                  />
                  <CardsDataComponent
                    status="PASSED"
                    project={pdfData.name}
                    baseURL={pdfData.url}
                    path={dataJson.webPath}
                    errorArray={dataJson.errorArray}
                    passedArray={dataJson.passedArray}
                    notApplArray={dataJson.notApplArray}
                    dynamicScope={pdfData[scopeKey]}
                    scopeKey={scopeKey}
                  />
                  <CardsDataComponent
                    status="ERROR"
                    project={pdfData.name}
                    baseURL={pdfData.url}
                    path={dataJson.webPath}
                    errorArray={dataJson.errorArray}
                    passedArray={dataJson.passedArray}
                    notApplArray={dataJson.notApplArray}
                    dynamicScope={pdfData[scopeKey]}
                    scopeKey={scopeKey}
                  />
                  <CardsDataComponent
                    status="NOT"
                    project={pdfData.name}
                    baseURL={pdfData.url}
                    path={dataJson.webPath}
                    errorArray={dataJson.errorArray}
                    passedArray={dataJson.passedArray}
                    notApplArray={dataJson.notApplArray}
                    dynamicScope={pdfData[scopeKey]}
                    scopeKey={scopeKey}
                  />
                </React.Fragment>
              ))}
              <EndComponent />
            </div>
          </div>
        </div>
      </IframeComponent>
    </Container>
  )
}

export default PDFPreviewPage
