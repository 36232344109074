/* eslint-disable no-unused-vars */
import React, { useState, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Container from '../layout/container/Container'
import { ChevronUpIcon, ChevronDownIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import { formatTimestamp } from '../../utils/dateUtils'
import { paginate } from '../../utils/paginationUtils'
import Button from '../common/Button'
import { PdfIcon } from '../../img/PdfIcon'

const ListComponent = ({ projects, currentPage, onPageChange, footer, sortOrder, sortField, onSortChange, listType }) => {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate()

  const handleNavigateToCreate = () => {
    navigate(`/create/${listType}`)
  }

  const handleNavigateToDetails = (id) => {
    navigate(`/pdf/${listType}/${id}`)
  }

  console.log('projects', projects, listType, projects[0]?.id)

  const buttonText = listType === 'projects' ? 'New App analysis' : 'New Task analysis'

  const currentPageProjects = paginate(projects, currentPage, 7)

  return (
    <>
      <Container>
        <div>
          {/* Header section */}
          <div className="flex items-center justify-between mb-5">
            <h1 className="text-lg font-semibold">{listType === 'projects' ? 'Web apps analysis' : 'Task analysis'}</h1>
            <div className="flex">
              <Button className="default-button" onClick={handleNavigateToCreate} logo={null} text={buttonText} />
            </div>
          </div>

          {/* Projects list */}
          <ul className="pt-2">
            {/* Column headers */}
            <ul className="w-full">
              <li className="grid grid-cols-[1fr_62px_130px_82px] items-center py-3">
                <span
                  onClick={() => {
                    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc'
                    onSortChange('name', newOrder)
                  }}
                  className="text-xs font-medium text-gray-500 flex items-center gap-1 cursor-pointer hover:text-gray-700"
                >
                  Project <ChevronUpDownIcon className="h-4 w-4" />
                </span>
                <span
                  onClick={() => {
                    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc'
                    onSortChange('urls', newOrder)
                  }}
                  className="text-xs px-2 font-medium text-gray-500 flex items-center gap-1 cursor-pointer hover:text-gray-700"
                >
                  URLs <ChevronUpDownIcon className="h-4 w-4" />
                </span>
                <span
                  onClick={() => {
                    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc'
                    onSortChange('environment', newOrder)
                  }} className="text-xs px-2 font-medium text-gray-500 flex items-center gap-1 cursor-pointer hover:text-gray-700">
                  Environment <ChevronUpDownIcon className="h-4 w-4" />
                </span>
                <div></div> {/* For the View button column */}
              </li>

              <hr className="border-t border-gray-200" />

              {currentPageProjects.map((project) => (
                <li
                  key={project.id}
                  onClick={() => handleNavigateToDetails(project.id)}
                  className="grid grid-cols-[1fr_62px_130px_82px] items-center hover:bg-gray-50 cursor-pointer border-b border-gray-200"
                >
                  {/* Project Name and Timestamp */}
                  <div className="flex items-center justify-self-start py-4 pr-2">
                    <div className="w-auto h-auto mr-3">
                      <PdfIcon />
                    </div>
                    <div className="max-w-36 @[500px]:max-w-none">
                      <h2 className="font-medium text-sm truncate">{project.name}</h2>
                      <p className="text-sm text-gray-500 truncate">{formatTimestamp(project.timestamp)}</p>
                    </div>
                  </div>

                  {/* URLs */}
                  <div className="items-center justify-center px-2 py-1 flex ">
                    <span className="inline-flex px-2 py-1 text-xs border-[1.5px] items-center border-bottle-green-2 max-h-5 max-w-8 text-bottle-green-1 rounded-full justify-center justify-self-start leading-[1.125rem]">
                      {project.webpages.length}
                    </span>
                  </div>
                  {/* Environment */}
                  <div className="px-2 py-1 flex items-center">
                    <span className={`inline-flex whitespace-nowrap text-xs items-center justify-center gap-1 leading-[1.125rem] pr-2 pl-1.5 py-0.5 w-fit max-w-32 max-h-5 rounded-full border-[1px] font-medium ${project.environment === 'Production'
                      ? 'border-light-blue bg-pale-blue text-dark-blue'
                      : project.environment === 'Pre-Production'
                        ? 'border-light-pink bg-pale-pink text-dark-pink'
                        : 'border-gray-300 bg-gray-50 text-gray-600'
                      }`}>
                      <span className={`rounded-full w-[6px] h-[6px] items-center justify-center ${project.environment === 'Production'
                        ? 'bg-mid-blue'
                        : project.environment === 'Pre-Production'
                          ? 'bg-mid-pink'
                          : 'bg-gray-600'
                        }`}></span>
                      {project.environment || 'No environment'}
                    </span>
                  </div>
                  {/* View Button */}
                  <button
                    onClick={(e) => {
                      e.stopPropagation() // Prevent navigating when clicked
                      handleNavigateToDetails(project.id)
                    }}
                    className="flex items-center justify-center font-bold text-sm text-text-violet text-center hover:text-purple-800 px-6 py-4"
                  >
                    View
                  </button>
                </li>
              ))}
            </ul>

            {/* Pagination */}
            {footer}
          </ul>
        </div>
      </Container>
    </>
  )
}

export default ListComponent
